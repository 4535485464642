/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ticketLog = /* GraphQL */ `
  mutation TicketLog($input: TicketLogInput!) {
    ticketLog(input: $input)
  }
`;
export const validateTicket = /* GraphQL */ `
  mutation ValidateTicket($input: ValidateTicketInput!) {
    validateTicket(input: $input) {
      status
      ticket {
        id
        eventId
        ticketId
        metadata
        validated
        active
        log
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const sendCommand = /* GraphQL */ `
  mutation SendCommand($input: SendCommandInput!) {
    sendCommand(input: $input)
  }
`;
export const updateCommandResponse = /* GraphQL */ `
  mutation UpdateCommandResponse($input: UpdateCommandInput!) {
    updateCommandResponse(input: $input)
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCommand = /* GraphQL */ `
  mutation DeleteCommand(
    $input: DeleteCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    deleteCommand(input: $input, condition: $condition) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      eventId
      ticketId
      metadata
      validated
      active
      log
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCommand = /* GraphQL */ `
  mutation CreateCommand(
    $input: CreateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    createCommand(input: $input, condition: $condition) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand(
    $input: UpdateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    updateCommand(input: $input, condition: $condition) {
      id
      scannerId
      type
      timestamp
      data
      response
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
